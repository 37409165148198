export default {
    init() {
        window.dataLayer = window.dataLayer || []
    },
    getGTMDL() {
        return window && window.dataLayer
    },
    push(...data) {
        if (!this.getGTMDL()) {
            this.init()
        }
        this.getGTMDL().push(...data)
    },
}
