enum COOKIE_NAMES {
    OAUTH2_ACCESS_TOKEN = 'oauth2AccessToken',
    OAUTH2_REFRESH_TOKEN = 'oauth2RefreshToken',
    OAUTH2_ID_TOKEN = 'oauth2IdToken',
    OAUTH2_REALM = 'oauth2Realm',
    EXISTING_USER = 'isExistingUser',
    INVITEE = 'GymondoInvitee',
    INVITEE_TOKEN = 'GymondoInviteeToken',
    AFFILIATE_ID = 'affiliate_ID',
    USER_REGION = 'userRegion',
    INFLUENCER_SOURCE = 'influencer.source',
    USER_LOCALE = 'userLocale',
    INTERNATIONALIZATION = 'i18n',
    COOKIE_CONSENT = 'cookieConsent',
}

export default COOKIE_NAMES
