import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import InputNote from '../common/InputNote'
import InputLabel from '../common/InputLabel'

export const BASE_CLASS_NAME = 'switch'
export const LABEL_CLASS_NAME = `${BASE_CLASS_NAME}-label`
const CONTAINER_CLASS_NAME = `${BASE_CLASS_NAME}-container`
export const ERROR_CLASS_NAME = `${CONTAINER_CLASS_NAME}--invalid`
export const DISABLED_CLASS_NAME = `${CONTAINER_CLASS_NAME}--disabled`

const Switch = ({
    className,
    labelClassName,
    containerClassName,
    i18nValues,
    label,
    field,
    form: { touched, errors },
    ...rest
}) => {
    const hasError = touched[field.name] && errors[field.name]
    const { ...fieldParams } = field

    return (
        <div className={cn(BASE_CLASS_NAME, containerClassName)}>
            <input
                id={fieldParams.name}
                type="checkbox"
                checked={fieldParams.value}
                {...fieldParams}
                {...rest}
            />
            {(label ||
                (i18nValues && i18nValues.checked && i18nValues.unchecked)) && (
                <InputLabel
                    htmlFor=""
                    className={cn(LABEL_CLASS_NAME, labelClassName)}
                >
                    {label ? (
                        <span className="checked">{label}</span>
                    ) : (
                        <>
                            <span className="checked">
                                {i18nValues.checked}
                            </span>
                            <span className="unchecked">
                                {i18nValues.unchecked}
                            </span>
                        </>
                    )}
                </InputLabel>
            )}
            <div
                className={cn(
                    className,
                    CONTAINER_CLASS_NAME,
                    hasError && ERROR_CLASS_NAME,
                    field.disabled && DISABLED_CLASS_NAME
                )}
            >
                <InputLabel
                    className="switch-slider"
                    htmlFor={fieldParams.name}
                />
                {hasError && <InputNote>{errors[fieldParams.name]}</InputNote>}
            </div>
        </div>
    )
}

Switch.propTypes = {
    /**
     * Css class to pass to the switch
     */
    className: PropTypes.string,
    /**
     * Css class to pass to the label
     */
    labelClassName: PropTypes.string,
    /**
     * Css class to pass to the wrapping div
     */
    containerClassName: PropTypes.string,
    /**
     * Label for switch
     */
    label: PropTypes.string,
    /**
     * Label object for two switch states
     */
    i18nValues: PropTypes.shape({
        checked: PropTypes.string,
        unchecked: PropTypes.string,
    }),
    /**
     * Field object passed by the Formik Field component
     * contains { name, value, onChange, onBlur }
     */
    field: PropTypes.object.isRequired,
    /**
     * Form object passed by Formik
     * also contains values, setXXXX, handleXXXX, dirty, isValid, status, etc.
     */
    form: PropTypes.object.isRequired,
}

Switch.defaultProps = {
    className: null,
    labelClassName: null,
    containerClassName: null,
    i18nValues: null,
    label: null,
}

export default Switch
