import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '../../button'
import {
    DEFAULT_VALUES,
    CONSENT_KEYS,
    STATES,
    PROMPT_PATTERNS,
} from '../config/config'
import replacePatternToComponent from '../../../utils/replacePatternToComponent'
import style from '../cookie-consent.module.scss'

const setPermissionToTrackers = () =>
    CONSENT_KEYS &&
    CONSENT_KEYS.reduce(
        (keys, key) => ({
            ...keys,
            [key]: true,
        }),
        {}
    )

const Prompt = ({
    i18nValues,
    cookiePolicyLink,
    privacyPolicyLink,
    onAccept,
    onSetState,
}) => {
    const content = replacePatternToComponent(
        i18nValues.text,
        PROMPT_PATTERNS,
        [
            <a
                className="link"
                key={i18nValues.privacy.label}
                href={privacyPolicyLink}
            >
                {i18nValues.privacy.label}
            </a>,
            <a
                className="link"
                key={i18nValues.cookiePolicy.label}
                href={cookiePolicyLink}
            >
                {i18nValues.cookiePolicy.label}
            </a>,
            <span
                className="link"
                key={i18nValues.acceptMandatory}
                onClick={() => onAccept(DEFAULT_VALUES)}
            >
                {i18nValues.acceptMandatory}
            </span>,
            <span
                className="link"
                key={i18nValues.settings}
                onClick={() => onSetState(STATES.SETTINGS)}
            >
                {i18nValues.settings}
            </span>,
        ]
    )

    return (
        <div className={cn(style.contentWrapper, style.promptWrapper)}>
            {i18nValues.title && (
                <h2 className={style.title}>{i18nValues.title}</h2>
            )}
            <div className={style.content}>
                <p className={style.description}>{content}</p>

                <Button
                    className={style.button}
                    size="medium"
                    onClick={() => onAccept(setPermissionToTrackers())}
                >
                    {i18nValues.button}
                </Button>
            </div>
        </div>
    )
}

Prompt.propTypes = {
    i18nValues: PropTypes.object.isRequired,
    cookiePolicyLink: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
}

export default Prompt
