import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const BASE_CLASS_NAME = 'label'

const InputLabel = ({ children, htmlFor, className }) => (
    <label
        className={cn(BASE_CLASS_NAME, className)}
        htmlFor={htmlFor}
    >
        {children}
    </label>
)

InputLabel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    htmlFor: PropTypes.string.isRequired,
    className: PropTypes.string
}

InputLabel.defaultProps = {
    children: '',
    className: null
}

export default InputLabel
