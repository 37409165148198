import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const BASE_CLASS_NAME = 'input-note'
const INPUT_NOTE_ERROR = `${BASE_CLASS_NAME}--invalid`

const InputNote = ({ children }) => (
    <div
        className={cn(
            BASE_CLASS_NAME,
            INPUT_NOTE_ERROR
        )}
    >
        {children}
    </div>
)

InputNote.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default InputNote
