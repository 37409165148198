import LOCALES from './locales.enum'
import LANGUAGES from './languages.enum'
import COOKIE_NAMES from './cookie-names.enum'
import LOCAL_STORAGE_KEYS from './local-storage-keys.enum'
import SESSION_STORAGE_KEYS from './session-storage-keys.enum'
import COOKIE_CONSENT_KEYS from './cookie-consent-keys.enum'
import HEADERS from './headers.enum'
import EVENTS from './events.enum'

export {
    LOCALES,
    LANGUAGES,
    COOKIE_NAMES,
    LOCAL_STORAGE_KEYS,
    SESSION_STORAGE_KEYS,
    COOKIE_CONSENT_KEYS,
    HEADERS,
    EVENTS,
}
