import { useEffect, useRef } from 'react'
/**
 * Hook to get the previous value of a variable
 * @param {*} value - inital value
 */
const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })

    return ref.current
}

export default usePrevious
