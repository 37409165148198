import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '../../button'
import Switch from '../../switch'
import Icon from '../../icon'
import ArrowIcon from '../../../svg/arrow-bold.svg'
import { STATES, SETTINGS_PATTERNS, CONSENT_KEYS } from '../config/config'
import replacePatternToComponent from '../../../utils/replacePatternToComponent'
import style from '../cookie-consent.module.scss'
import { COOKIE_CONSENT_KEYS } from '../../../enums'

const Settings = ({
    i18nValues,
    cookiePolicyLink,
    values,
    onAccept,
    onSetState,
    onSetValues,
    showBackButton,
}) => {
    const [visible, setVisible] = useState(null)

    const toggleItem = key => {
        if (visible === key) {
            setVisible(null)
        } else {
            setVisible(key)
        }
    }

    const content = replacePatternToComponent(
        i18nValues.text,
        SETTINGS_PATTERNS,
        [
            <a className="link" href={cookiePolicyLink}>
                {i18nValues.cookiePolicy.label}
            </a>,
        ]
    )

    if (!i18nValues || !values) {
        return null
    }

    const sortedKeys = Object.keys(values).sort(
        (a, b) => CONSENT_KEYS.indexOf(a) - CONSENT_KEYS.indexOf(b)
    )

    return (
        <div className={style.contentWrapper}>
            <h2 className={style.title}>{i18nValues.title}</h2>
            <div className={cn(style.description, style.settings)}>
                {content}
            </div>
            <div className={style.switchWrapper}>
                {sortedKeys.map(key => (
                    <Fragment key={i18nValues[key.toLowerCase()].label}>
                        <div className={style.switchItem}>
                            <div
                                className={style.switchTitle}
                                onClick={() => toggleItem(key)}
                            >
                                <div className={style.labelWrapper}>
                                    <span className={style.label}>
                                        {i18nValues[key.toLowerCase()].label}
                                    </span>

                                    <div
                                        className={style.toggle}
                                        onClick={() => toggleItem(key)}
                                    >
                                        <Icon
                                            data={ArrowIcon}
                                            className={cn(
                                                style.icon,
                                                visible === key &&
                                                    style.iconOpen
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.switchDetails}>
                                <Switch
                                    labelClassName={style.switchLabel}
                                    containerClassName={style.switchContainer}
                                    i18nValues={{
                                        checked:
                                            i18nValues[key.toLowerCase()]
                                                .switch ||
                                            i18nValues.switch.activated,
                                        unchecked:
                                            i18nValues.switch.deactivated,
                                    }}
                                    form={{
                                        touched: {},
                                        errors: {},
                                    }}
                                    field={{
                                        onChange: () =>
                                            onSetValues(v => ({
                                                ...v,
                                                [key]: !v[key],
                                            })),
                                        name: key,
                                        value: values[key],
                                        disabled:
                                            key ===
                                            COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={cn(
                                style.switchDescription,
                                visible === key && style.switchDescriptionOpen
                            )}
                            onClick={() => toggleItem(key)}
                        >
                            {i18nValues[key.toLowerCase()].description}
                        </div>
                    </Fragment>
                ))}
            </div>
            <div className={style.actions}>
                {showBackButton && (
                    <div
                        className={style.backButton}
                        onClick={() => onSetState(STATES.PROMPT)}
                    >
                        {i18nValues.button.back}
                    </div>
                )}
                <Button
                    size="medium"
                    onClick={() => onAccept(values)}
                    className={style.saveButton}
                >
                    {i18nValues.button.save}
                </Button>
            </div>
        </div>
    )
}

Settings.defaultProps = {
    showBackButton: false,
}

Settings.propTypes = {
    i18nValues: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onAccept: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    onSetValues: PropTypes.func.isRequired,
    showBackButton: PropTypes.bool,
}

export default Settings
