import { useState, useEffect } from 'react'
/**
 * Hook to get the scrollY value of the page
 */
const useScroll = () => {
    const isClient = typeof window !== 'undefined'
    const [scrollY, setScrollY] = useState(isClient && window.pageYOffset)

    useEffect(() => {
        if (!isClient) {
            return false
        }
        const handleScroll = () => setScrollY(window.pageYOffset)
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isClient])

    return scrollY
}

export default useScroll
