enum SESSION_STORAGE_KEYS {
    CHANNEL_ID = 'channelId',
    CHANNEL = 'channel',
    RECOMMENDED_PROGRAM_ID = 'recommended',
    CAMPAIGN_VOUCHER = 'campaign.voucher',
    EXPLICIT_VOUCHER = 'signup.explicitVoucher',
    SIGNUP_CAMPAIGN = 'signup.campaign',
    SIGNUP_VOUCHER = 'signup.voucher'
}

export default SESSION_STORAGE_KEYS
