import storageSrrFallback from './storageSSRFallback'

const storage = storageSrrFallback('sessionStorage')
const get = key => (storage.getItem(key) ? JSON.parse(storage.getItem(key)) : null)
const set = (key, value) => storage.setItem(key, JSON.stringify(value))
const remove = key => storage.removeItem(key)

export default {
    get,
    set,
    remove
}
