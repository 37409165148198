import _ from 'lodash'
import GoogleAnalytics from '../api/service/tracker'

const EVENT_TYPES = {
    CLICK: 'click',
    BLUR: 'blur',
    CHANGE: 'change',
}

const EVENTS = {
    TRACKING: 'event_tracking',
    SET_CUSTOM_DIMENSION: 'set_custom_dimension',
    VIRTUAL_PAGEVIEW: 'VirtualPageview',
}

const push = (...data) => {
    GoogleAnalytics.push(...data)
}

const getDataset = el => {
    while (el) {
        if (_.keys(el.dataset).length > 0) {
            return el.dataset
        }
        el = el.parentNode
    }

    return null
}

const formatTrackEvt = ({ category, action, label, value }) => ({
    event: EVENTS.TRACKING,
    eventCategory: category,
    ...(action && { eventAction: action }),
    ...(label && { eventLabel: label }),
    ...(value && { eventValue: value }),
})

const registerTracker = () => {
    const onEvtTriggered = (ev, type) => {
        const dataset = ev && getDataset(ev.target)

        if (dataset && dataset.evt === type) {
            push(formatTrackEvt(dataset))
        }
    }

    // add event listener to document
    const evtList = [EVENT_TYPES.CLICK, EVENT_TYPES.BLUR, EVENT_TYPES.CHANGE]
    evtList.forEach(event =>
        document.addEventListener(event, ev => onEvtTriggered(ev, event), true)
    )
}

export default {
    EVENT_TYPES,
    EVENTS,
    push,
    registerTracker,
}
