import { COOKIE_CONSENT_KEYS } from '../../../enums'
import { Prompt, Settings } from '../component'

export const DEFAULT_VALUES = {
    [COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN]: true,
    [COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN]: false,
    [COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN]: false,
    [COOKIE_CONSENT_KEYS.GYM_CONSENT_SOCIAL_LOGIN_OPTIN]: false,
    [COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN]: false,
}

export const CONSENT_KEYS = [
    COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN,
    COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN,
    COOKIE_CONSENT_KEYS.GYM_CONSENT_SOCIAL_LOGIN_OPTIN,
    COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN,
    COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN,
]

export const STATES = {
    PROMPT: 'prompt',
    SETTINGS: 'settings',
}

export const STATE_TO_COMPONENT_MAP = {
    [STATES.PROMPT]: Prompt,
    [STATES.SETTINGS]: Settings,
}

export const SETTINGS_PATTERNS = ['%%SETTINGS_LINK%%']
export const PROMPT_PATTERNS = [
    '%%PRIVACY_LINK%%',
    '%%COOKIE_POLICY%%',
    '%%ACCEPT_MANDATORY_LINK%%',
].concat(SETTINGS_PATTERNS)

export const IFRAME_EVENT_TYPES = {
    COOKIE_CONSENT_GIVEN: 'COOKIE_CONSENT_GIVEN',
    COOKIE_EVENT_RECEIVED: 'COOKIE_EVENT_RECEIVED',
}

export const COOKIE_EXPIRY = 36500 // 100 years
