import { useEffect, useState } from 'react'

const SDK_LOADED_EVENT_TYPE = 'sdkLoaded'

const windowGlobal = (typeof window !== 'undefined' && window) || {}

windowGlobal.scriptLoadedStatus = windowGlobal.scriptLoadedStatus || {
    fbLoaded: false,
    gapiLoaded: false,
    appleLoaded: false,
}

const dispatchLoadedEvent = () => {
    const event = document.createEvent('Event')
    event.initEvent(SDK_LOADED_EVENT_TYPE, true, false)
    window.dispatchEvent(event)
}

windowGlobal.fbAsyncInit = () => {
    window.scriptLoadedStatus.fbLoaded = true
    dispatchLoadedEvent()
}

windowGlobal.gapiLoaded = () => {
    window.scriptLoadedStatus.gapiLoaded = true
    dispatchLoadedEvent()
}

windowGlobal.appleLoaded = () => {
    window.scriptLoadedStatus.appleLoaded = true
    dispatchLoadedEvent()
}

const useSocialLoginSdksLoaded = () => {
    const [fbLoaded, setFbLoaded] = useState(
        windowGlobal.scriptLoadedStatus.fbLoaded
    )
    const [gapiLoaded, setGapiLoaded] = useState(
        windowGlobal.scriptLoadedStatus.gapiLoaded
    )
    const [appleLoaded, setAppleLoaded] = useState(
        windowGlobal.scriptLoadedStatus.appleLoaded
    )

    useEffect(() => {
        const handleSdkLoadedEvent = () => {
            setFbLoaded(window.scriptLoadedStatus.fbLoaded)
            setGapiLoaded(window.scriptLoadedStatus.gapiLoaded)
            setAppleLoaded(window.scriptLoadedStatus.appleLoaded)
        }

        window.addEventListener(
            SDK_LOADED_EVENT_TYPE,
            handleSdkLoadedEvent,
            false
        )

        return () =>
            window.removeEventListener(
                SDK_LOADED_EVENT_TYPE,
                handleSdkLoadedEvent,
                false
            )
    }, [])

    return {
        fbLoaded,
        gapiLoaded,
        appleLoaded,
    }
}

export default useSocialLoginSdksLoaded
