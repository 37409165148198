import moment from 'moment'
import Cookies from 'js-cookie'

import { DEFAULT_VALUES, COOKIE_EXPIRY } from './config/config'
import { Tracker } from '../../utils'
import { COOKIE_CONSENT_KEYS, COOKIE_NAMES } from '../../enums'

export const CONSENT_CHANGE_EVENT_NAME = 'cookie_consent_changed'

type loadScriptType = (
    src: string,
    loadedCallback?: () => void,
    errorCallback?: () => void
) => void

export const loadScript: loadScriptType = (
    src,
    loadedCallback,
    errorCallback
) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.async = true

    if (loadedCallback) {
        script.addEventListener('load', loadedCallback, false)
    }

    if (errorCallback) {
        script.addEventListener('error', errorCallback, false)
    }

    document.getElementsByTagName('body')[0].appendChild(script)
}

export const loadGTM = (GTMId: string) => {
    const defaultGtmLoadUrl = 'https://data.gymondo.com/gtm.js'
    Tracker.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
    })

    loadScript(`${defaultGtmLoadUrl}?id=${GTMId}`, undefined, undefined)
}

export const getCookieDomain = () => {
    const hostNameArr = window.location.hostname.split('.')

    return hostNameArr.splice(hostNameArr.length - 2).join('.')
}

export const getStoredValues = (withDefaults: boolean = true) => {
    const consentCookie = Cookies.get(COOKIE_NAMES.COOKIE_CONSENT)

    let currentValues = {}

    try {
        if (consentCookie) {
            currentValues = JSON.parse(consentCookie)
        }
    } catch (error) {
        currentValues = {}
    }

    return {
        ...(withDefaults ? DEFAULT_VALUES : {}),
        ...currentValues,
    }
}

export const storeValues = (values: Record<COOKIE_CONSENT_KEYS, boolean>) => {
    const cookieDomain = getCookieDomain()

    const savedValues = {
        ...DEFAULT_VALUES,
        ...values,
    }

    Cookies.set(COOKIE_NAMES.COOKIE_CONSENT, JSON.stringify(savedValues), {
        domain: cookieDomain,
        expires: COOKIE_EXPIRY,
    })
}

export const getConsentValue = (key: COOKIE_CONSENT_KEYS) => {
    const consentValues = getStoredValues(false)

    return consentValues[key]
}

export const setConsnetValue = (key: COOKIE_CONSENT_KEYS, value: boolean) => {
    const newCookies = {
        ...getStoredValues(),
        [key]: value,
    }

    return storeValues(newCookies as Record<COOKIE_CONSENT_KEYS, boolean>)
}

export const generateIframePayload = (
    values: Record<COOKIE_CONSENT_KEYS, boolean>
) => {
    const expiryDatetime = moment()
        .add(COOKIE_EXPIRY, 'day')
        .toDate()
        .toUTCString()

    return `${COOKIE_NAMES.COOKIE_CONSENT}=${JSON.stringify(
        values
    )};expires=${expiryDatetime};SameSite=None;Secure;Path=/;domain=`
}

export const getTrackingAllowed = (values: Record<COOKIE_CONSENT_KEYS, any>) =>
    values[COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN] ||
    values[COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN] ||
    values[COOKIE_CONSENT_KEYS.GYM_CONSENT_SOCIAL_LOGIN_OPTIN] ||
    values[COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN]

export const getValueToggled = (
    values: Record<COOKIE_CONSENT_KEYS, boolean>,
    prevValues: Record<COOKIE_CONSENT_KEYS, boolean>
) => {
    const changedValues = Object.keys(values).map(key => {
        const cookieConsentKey = key as COOKIE_CONSENT_KEYS

        return values[cookieConsentKey] !== prevValues[cookieConsentKey]
            ? values[cookieConsentKey]
            : null
    })

    return (
        changedValues.find(v => v === true) ||
        changedValues.find(v => v === false)
    )
}

export const sendConsentChangedEvent = () => {
    const event = document.createEvent('Event')
    event.initEvent(CONSENT_CHANGE_EVENT_NAME, true, false)
    window.dispatchEvent(event)
}
