import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import jsCookie from 'js-cookie'
import { COOKIE_NAMES, LOCALES, COOKIE_CONSENT_KEYS } from '../../enums'
import { Tracker } from '../../utils'
import {
    STATES,
    STATE_TO_COMPONENT_MAP,
    IFRAME_EVENT_TYPES,
} from './config/config'
import lang from './lang/lang.json'
import style from './cookie-consent.module.scss'
import {
    CONSENT_CHANGE_EVENT_NAME,
    getStoredValues,
    getTrackingAllowed,
    getValueToggled,
    loadGTM,
    storeValues,
    getConsentValue,
    generateIframePayload,
} from './util'
import { isEU } from '../../utils/intl'
import { useSocialLoginSdksLoaded } from '../../hooks'
import { useCallback } from 'react'

const CookieConsent = ({
    cookiePolicyLink,
    privacyPolicyLink,
    iframeUrl,
    locale,
    state,
    GTMId,
    GTMLoadFallbackUrl,
    onConsentGiven,
}) => {
    useSocialLoginSdksLoaded()
    const iframeRef = useRef()
    const cookieAccepted = getConsentValue(
        COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN
    )
    const isEuUser = isEU()

    const [isVisible, setVisible] = useState(
        (!cookieAccepted && isEuUser) || !!state
    )
    const [showIframe, setShowIframe] = useState(
        !!iframeUrl && !cookieAccepted && isEuUser
    )

    const finalLocale = (
        locale ||
        jsCookie.get(COOKIE_NAMES.USER_LOCALE) ||
        LOCALES.DE_DE
    ).substring(0, 2)

    const storedValues = getStoredValues()

    const [currentState, setCurrentState] = useState(state || STATES.PROMPT)
    const [loadScript, setLoadScript] = useState(
        getTrackingAllowed(storedValues) || !isEuUser
    )
    const [values, setValues] = useState(storedValues)

    const accept = useCallback(
        (vals, toggled) => {
            const prevValues = getStoredValues()
            storeValues(vals)
            const valueToggled = getValueToggled(vals, prevValues)
            if (valueToggled === false) {
                window.location.reload()
            } else if (valueToggled === true || toggled) {
                setLoadScript(true)
                Tracker.push({ event: CONSENT_CHANGE_EVENT_NAME })
            }
            // eslint-disable-next-line no-unused-expressions
            iframeRef.current?.contentWindow.postMessage(
                {
                    type: IFRAME_EVENT_TYPES.COOKIE_CONSENT_GIVEN,
                    payload: generateIframePayload(vals),
                },
                '*'
            )
            onConsentGiven(valueToggled)
            setVisible(false)
        },
        [iframeRef, onConsentGiven]
    )

    useEffect(() => {
        const handleEvent = e => {
            if (e.origin === window.origin) {
                return
            }
            if (e.data?.type === IFRAME_EVENT_TYPES.COOKIE_EVENT_RECEIVED) {
                setShowIframe(false)
            }
        }
        window.addEventListener('message', handleEvent, false)

        return () => window.removeEventListener('message', handleEvent, false)
    }, [accept])

    useEffect(() => {
        const handleConsentChanged = () => {
            const vals = getStoredValues()
            accept(vals, true)
            setValues(vals)
        }
        window.addEventListener(
            CONSENT_CHANGE_EVENT_NAME,
            handleConsentChanged,
            false
        )

        return () =>
            window.removeEventListener(
                CONSENT_CHANGE_EVENT_NAME,
                handleConsentChanged,
                false
            )
    }, [accept])

    useEffect(() => {
        if (state) {
            setCurrentState(state)
            setVisible(true)
        }
    }, [state])

    useEffect(() => {
        if (loadScript) {
            loadGTM(GTMId, GTMLoadFallbackUrl)
        }
    }, [GTMId, GTMLoadFallbackUrl, loadScript])

    const Component = STATE_TO_COMPONENT_MAP[currentState]

    return (
        <>
            {showIframe && (
                <iframe
                    ref={iframeRef}
                    title="cross-domain-cookie-consent"
                    src={iframeUrl}
                    className={style.iframe}
                    frameBorder="0"
                    height="0px"
                    width="0px"
                />
            )}
            {isVisible && (
                <div className={style.wrapper}>
                    <Component
                        i18nValues={{
                            ...lang[finalLocale],
                            ...lang[finalLocale][currentState],
                        }}
                        cookiePolicyLink={
                            cookiePolicyLink ||
                            lang[finalLocale].cookiePolicy.link
                        }
                        privacyPolicyLink={
                            privacyPolicyLink || lang[finalLocale].privacy.link
                        }
                        values={values}
                        onAccept={accept}
                        onSetState={setCurrentState}
                        onSetValues={setValues}
                        showBackButton={!state}
                    />
                </div>
            )}
        </>
    )
}

CookieConsent.propTypes = {
    GTMId: PropTypes.string.isRequired,
    GTMLoadFallbackUrl: PropTypes.string,
    cookiePolicyLink: PropTypes.string,
    privacyPolicyLink: PropTypes.string,
    iframeUrl: PropTypes.string,
    locale: PropTypes.string,
    state: PropTypes.oneOf(['prompt', 'settings']),
    onConsentGiven: PropTypes.func,
}

CookieConsent.defaultProps = {
    GTMLoadFallbackUrl: null,
    cookiePolicyLink: null,
    privacyPolicyLink: null,
    iframeUrl: null,
    state: null,
    locale: null,
    onConsentGiven: () => ({}),
}

export default CookieConsent
