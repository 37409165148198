import React from 'react'
import PropTypes from 'prop-types'
/**
 * Icon
*/
const Icon = (props) => {
    const { data: SVG, ...rest } = props

    return <SVG {...rest} />
}

Icon.propTypes = {
    /**
    * The SVG imported directly into module
    */
    data: PropTypes.elementType.isRequired
}

export default Icon
