enum LOCAL_STORAGE_KEYS {
    CONSENT = 'cookies.accepted',
    PRODUCTS = 'products.original',
    SELECTED_PRODUCT = 'product.selected',
    SIGNUP_ACTION = 'signup.action',
    SIGNUP_IS_TRIAL = 'signup.isTrial',
    SIGNUP_ZANOX = 'signup.zanox',
    SIGNUP_SOVENDUS = 'signup.sovendus',
    USER_LOGGEDIN = 'user.loggedIn',
    EXPERIMENT_VARIANT = 'experiment.variant',
    ONBOARDING_STATE = 'onboarding.state',
    MUSIC_VOLUME = 'musicVolume',
    VIDEO_QUALITY = 'videoQualityNew',
    VIDEO_MUSIC = 'videoMusic',
    EXPLANATORY_MODAL = 'explanatory.modal',
    WHAT_IS_NEW_WEEKLY_TARGET_MODAL = 'whatIsNewModal.weeklyTarget.closed',
    CHALLENGES_PROMOTION_MODAL = 'challengesPromotion.closed',
    FITNESS_BANNER = 'fitness.banner',
    CLOSED_SMART_BANNER = 'closedSmartBanner',
    CHALLENGE_MODAL_HOME = 'challengeModal.home',
}

export default LOCAL_STORAGE_KEYS
