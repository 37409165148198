import React, { Fragment } from 'react'

/**
 * Replace the %%<TEXT>%% patterns in a text with components (links or spans for ex.)
 * Note : The indices in the patterns array should correspond to the same indices of the components that
 * should replace it in the Components param
 * @param {string} text String to be modified
 * @param {array} patterns Array of patterns to be replaced in text
 * @param {*} Components Array of components that would be added to the text inplace of the patterns
 * @returns
 */
const replacePatternToComponent = (text, patterns, Components) => {
    const reg = new RegExp(`(${patterns.join('|')})`, 'gi')
    const textParts = text.split(reg)

    return textParts.map((part, index) => (
        <Fragment key={part + index}>
            {part.match(reg) ? Components[patterns.indexOf(part)] : part}
        </Fragment>
    ))
}

export default replacePatternToComponent
