import qs from 'query-string'

export default {
    getQueryParameter(location, queryName) {
        const queryParameter = location
            && qs.parse(location && location.search)

        return queryParameter && queryParameter[queryName]
    }
}
