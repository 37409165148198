import Cookies from 'js-cookie'

import { COOKIE_CONSENT_KEYS, COOKIE_NAMES } from '../enums'
import { isEU } from './intl'
import Tracker from './tracker'

export const getStoredConsents = () => {
    const consentCookie = Cookies.get(COOKIE_NAMES.COOKIE_CONSENT)

    let currentValues = {}

    try {
        if (consentCookie) {
            currentValues = JSON.parse(consentCookie)
        }
    } catch (error) {
        currentValues = {}
    }

    return currentValues
}

const getVariant = (experimentID, activateEvent = true) => {
    if (activateEvent) {
        Tracker.push({ event: 'optimize.activate', ...activateEvent })
    }

    const MAX_RETRIES = 30
    const RETRY_TIME_MS = 100

    let retries = 0

    // eslint-disable-next-line consistent-return
    return new Promise(resolve => {
        const shouldFallback =
            !getStoredConsents()[
                COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN
            ] && isEU()

        if (shouldFallback) {
            return resolve(false)
        }
        // eslint-disable-next-line consistent-return
        const fetchVariantInterval = window.setInterval(() => {
            if (window.google_optimize || retries === MAX_RETRIES) {
                const value = window?.google_optimize?.get(experimentID)

                window.clearInterval(fetchVariantInterval)

                return resolve(value || false)
            }
            retries++
        }, RETRY_TIME_MS)
    })
}

export default {
    getVariant,
}
